import React from "react";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";

class AnimatedDivider extends React.Component {
  constructor() {
    super();
    this.vantaRef = React.createRef();
    this.zoomLevel = 0.20;
    if (typeof window !== `undefined`) {
    const mediaQuery = window.matchMedia('(max-width: 1500px)')
      if (mediaQuery.matches) {
        this.zoomLevel = 0.10;
      } else {
        this.zoomLevel = 0.20;
      }
    }
  }
  componentDidMount() {
    this.vantaEffect = FOG({
      el: this.vantaRef.current,
      THREE: THREE,
      highlightColor: 0xff00f0,
      midtoneColor: 0xb3ff,
      minHeight: 75.00,
      minWidth: 1900.00,
      zoom: this.zoomLevel,
      speed: 3.60
    });
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }
  render() {
    return (
        <section className="animated_divider" ref={this.vantaRef}></section>
    );
  }
}
export default AnimatedDivider;
