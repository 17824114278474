import React from "react";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import LayoutSmall from "../components/layoutSmall";
import AnimatedDivider from "../components/AnimatedDivider";

const BlogArticle = ({ data }) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
            "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": `https://brandinavian.com/${data.wpPost.slug}/#webpage`,
        url: `https://brandinavian.com/${data.wpPost.slug}/`,
        name: `${data.wpPost.seo.title}`,
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        datePublished: `${data.wpPost.seo.opengraphPublishedTime}`,
        dateModified: `${data.wpPost.seo.opengraphModifiedTime}`,
        description: `${data.wpPost.seo.metaDesc}`,
        breadcrumb: {
          "@id": `https://brandinavian.com/${data.wpPost.slug}/#breadcrumb`,
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`https://brandinavian.com/${data.wpPost.slug}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `https://brandinavian.com/${data.wpPost.slug}/#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Home",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/articles",
              url: "https://brandinavian.com/articles",
              name: "Articles",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@type": "WebPage",
              "@id": `https://brandinavian.com/${data.wpPost.slug}`,
              url: `https://brandinavian.com/${data.wpPost.slug}`,
              name: `${data.wpPost.seo.title}`,
            },
          },
        ],
      },
      {
        "@type": "Article",
        "@id": `https://victoriousseo.com/${data.wpPost.slug}/#article`,
        isPartOf: {
          "@id": `https://victoriousseo.com/blog/${data.wpPost.slug}/#webpage`,
        },
        author: {
          "@id":
            "https://victoriousseo.com/#/schema/person/178f9563b092ab52841875fb27aff7ea",
        },
        headline: `${data.wpPost.seo.title}`,
        datePublished: `${data.wpPost.seo.opengraphPublishedTime}`,
        dateModified: `${data.wpPost.seo.opengraphModifiedTime}`,
        mainEntityOfPage: {
          "@id": `https://victoriousseo.com/blog/${data.wpPost.slug}/#webpage`,
        },
        publisher: { "@id": "https://brandinavian.com/#organization" },
        articleSection: "Technology & Science",
        inLanguage: "en-US",
      },
      {
        "@type": "Person",
        "@id": "https://brandinavian.com/#/schema/person/",
        name: `${data.wpPost.customPostMetaACF.whoIsTheAuthor}`,
        image: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#personlogo",
          inLanguage: "en-US",
          url: `https://brandinavian.com${data.wpPost.customPostMetaACF.authorImage.localFile.childImageSharp.original.src}`,
          caption: `${data.wpPost.customPostMetaACF.whoIsTheAuthor}`,
        },
        description: "",
      },
    ],
  };
  
  return (
    <LayoutSmall>
      <SEO
        lang="en-US"
        title={data.wpPost.seo.title}
        description={data.wpPost.seo.metaDesc}
        schemaMarkup={schema}
        article={true}
        image={data.wpPost.seo.opengraphImage.sourceUrl}
        publishTime={data.wpPost.seo.opengraphPublishedTime}
        modifiedTime={data.wpPost.seo.opengraphModifiedTime}
      />

      <div id="main" className="alt">
        <section className="pre-article">
          <div className="breadcrumb-nav">
            <Link to="/">Home</Link>
            <span className="breadcrumb-separator">/</span>
            <Link to="/articles">Articles</Link>
            <span className="breadcrumb-separator">/</span>
          </div>
          <header className="major article-title">
            <h1 dangerouslySetInnerHTML={{ __html: data.wpPost.title }} />
          </header>
          <div className="author-bio">
            <img
              className="author-img"
              src={
                data.wpPost.customPostMetaACF.authorImage.localFile
                  .childImageSharp.original.src
              }
              alt="Author image"
            />
            <div className="author-details">
              <p className="author-title">Author</p>
              <p className="author-name">
                {data.wpPost.customPostMetaACF.whoIsTheAuthor}
              </p>
            </div>
            <div className="publish-time">
              <p className="publish-title">Last updated</p>
              <p
                className="publish-timestamp"
                dangerouslySetInnerHTML={{ __html: data.wpPost.modified }}
              />
            </div>
          </div>
          <div className="article-tagbox">
            {data.wpPost.tags.nodes.map((node) => (
              <li key={node.id} className="tag">
                {node.name}
              </li>
            ))}
          </div>
          <Img className="article-header-img" fluid={data.wpPost.featuredImage.node.localFile.childImageSharp.fluid} />
        </section>
        <AnimatedDivider />
        <section id="one" className="article-section">
          <div
            className="inner article"
            dangerouslySetInnerHTML={{ __html: data.wpPost.content }}
          />
          <div className="inner article about-author">
            <div className="author-image-box">
              <img
                className="large-author-image"
                src={
                  data.wpPost.customPostMetaACF.authorImage.localFile
                    .childImageSharp.original.src
                }
              />
            </div>
            <div className="author-information">
            <header className="major special">
              <h2 className="large-author-name">
                {data.wpPost.customPostMetaACF.whoIsTheAuthor}
              </h2>
              </header>
              <p className="author-biography">
              {data.wpPost.customPostMetaACF.authorBio}
              </p>
              <p className="author-links" dangerouslySetInnerHTML={{__html:data.wpPost.customPostMetaACF.authorLinks}}/>
                  
            </div>
          </div>
        </section>
      </div>
    </LayoutSmall>
  );
};

export default BlogArticle;

export const query = graphql`
  query($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      content
      slug
      id
      modified(formatString: "MMMM D, YYYY")
      customPostMetaACF {
        whoIsTheAuthor
        authorBio
        authorLinks
        authorImage {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      tags {
        nodes {
          name
          id
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          altText
          sourceUrl
        }
        opengraphUrl
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
        }
        breadcrumbs {
          url
          text
        }
      }
      featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
  }
`;
